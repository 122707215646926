export const config = {

  // API_HOST: 'https://medscore-api.onrender.com',
 
  API_HOST:'https://medscore-api.azurewebsites.net'
  //  API_HOST: 'http://localhost:5001'
};

 

